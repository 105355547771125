import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        greenFilled: "bg-[#19FB9B] rounded",
        greenOutline:
          "border border-primaryGreen text-primaryGreen shadow hover:bg-primaryGreen hover:text-primaryGreen-foreground",
        grayFilled: "bg-[#3C3C3C] rounded",
        disabled: "bg-[#2B2B2B] text-[#5F5F5F] rounded",
        greenLink:
          "text-primaryGreen hover:text-primaryGreen-foreground hover:bg-transparent",
        blueLink:
          "text-primaryBlue hover:text-primaryBlue-foreground hover:bg-transparent",
        whiteOutline:
          "border border-white text-white shadow hover:bg-white hover:text-white-foreground",
        whiteLink:
          "text-white hover:text-white-foreground hover:bg-transparent",
        linear:
          "bg-gradient-to-r from-[#CC31E5] via-[#8069F0] to-[#24ADFE] text-white shadow hover:bg-gradient-to-l",
      },
      size: {
        default: "h-[40px] px-[20px] py-[12px]",
        sm: "h-[32px] px-[12px] py-[8x]",
        md: "h-[40px] px-[20px] py-[12px]",
        lg: "h-[48px] px-[24px] py-[12px]",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
