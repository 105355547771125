import { Text } from "./ui/text";
// import DiscordSVG from "@/assets/images/media/discord.svg";
// import FacebookSVG from "@/assets/images/media/facebook.svg";
// import InstagramSVG from "@/assets/images/media/instagram.svg";
// import LinkedinSVG from "@/assets/images/media/linkedin.svg";
// import YoutubeSVG from "@/assets/images/media/youtube.svg";
// import MediumSVG from "@/assets/images/media/medium.svg";
import TelegramSVG from "@/assets/images/media/telegram.svg";
import NOVAFISVG from "@/assets/images/nova-fi.svg";
import XSVG from "@/assets/images/media/x.svg";

const Footer = () => {
  // TODO: these looks a bit strange
  const MediaList = [
    {
      icon: TelegramSVG,
      link: "https://t.me/nvxcdotfi",
    },
    {
      icon: XSVG,
      link: "https://x.com/NVXC_fi",
    },
  ];

  // const DAORoutes = [
  //   {
  //     title: "Fellowship Program",
  //   },
  //   {
  //     title: "About Nova DAO",
  //   },
  //   {
  //     title: "Ecosystem",
  //   },
  // ];

  // const ResourcesRoutes = [
  //   {
  //     title: "FAQ",
  //   },
  //   {
  //     title: "Application",
  //   },
  //   {
  //     title: "WhitePaper",
  //   },
  // ];

  return (
    <div className="w-full flex flex-col md:flex-row py-16 px-4 md:px-[104px] gap-10 md:gap-[160px] bg-[#131313]">
      <div className="flex flex-col gap-6">
        <img src={NOVAFISVG} className="w-[180px]" />

        <div className="flex gap-[16px]">
          {MediaList.map(({ icon, link }, index) => (
            <a href={link} target="_blank">
              <img key={index} src={icon} className="w-6 h-6" />
            </a>
          ))}
        </div>

        <div className="flex gap-2">
          <Text
            mdVariant="b1"
            smVariant={"b1"}
            className="font-normal text-[#5F5F5F]"
          >
            © 2018-2025 NVXC.FI All rights reserved
          </Text>
          <Text
            mdVariant="b1"
            smVariant={"b1"}
            className="font-normal text-[#5F5F5F]"
          >
            |
          </Text>
          <a href="/whitepaper.pdf" target="_blank">
            <Text
              mdVariant="b1"
              smVariant={"b1"}
              className="font-normal text-[#5F5F5F] hover:text-white"
            >
              Whitepaper
            </Text>
          </a>
        </div>
      </div>

      {/* <div className="flex flex-col gap-6">
        <Text mdVariant="t2" smVariant={"t2"} className="font-medium">
          DAO
        </Text>

        <div className="flex flex-col gap-5">
          {DAORoutes.map((route) => (
            <Text
              mdVariant="b1"
              smVariant={"b1"}
              className="font-normal text-[#5F5F5F]"
            >
              {route.title}
            </Text>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <Text mdVariant="t2" smVariant={"t2"} className="font-medium">
          Resources
        </Text>

        <div className="flex flex-col gap-5">
          {ResourcesRoutes.map((route) => (
            <Text
              mdVariant="b1"
              smVariant={"b1"}
              className="font-normal text-[#5F5F5F]"
            >
              {route.title}
            </Text>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default Footer;
